<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import { useLocalization } from '/~/composables/localization'
import LoyaltyCard from '/~/drawer/loyalty-cards/components/loyalty-card.v2.vue'
import LoyaltyMembershipsV1 from '/~/drawer/loyalty-cards/loyalty-cards.vue'

export default {
  name: 'loyalty-cards',
  components: {
    LoyaltyCard,
    BaseAsidePage,
  },
  extends: LoyaltyMembershipsV1,
  setup() {
    const { translate } = useLocalization()

    return {
      translate,
      ...LoyaltyMembershipsV1.setup?.(...arguments),
    }
  },
  methods: {
    onCreate() {
      this.$router.push({
        hash: '#profile-loyalty-memberships-brands',
      })
    },
    onClick(card) {
      this.$router.push({
        hash: `#profile-loyalty-card-details/${card.id}`,
      })
    },
  },
}
</script>

<template>
  <base-aside-page
    ref="baseAsidePage"
    :title="`Loyalty ${translate('common.and')} Memberships`"
    :action="pageAction"
    no-padding
  >
    <div class="p-5">
      <base-state
        v-if="isLoyaltyCardsEmpty"
        title="Add Loyalty Cards"
        image="/empty/empty-loyalty-cards.svg"
        class="min-h-full"
      >
        <p>
          Securely store and manage your
          {{ translate('common.favourite') }} loyalty and membership cards in
          one central location.
        </p>

        <template #footer>
          <base-button @click="onCreate">Add Loyalty Card</base-button>
        </template>
      </base-state>

      <div class="grid grid-cols-2 gap-5">
        <template v-if="isLoyaltyCardsLoading">
          <base-card class="animate-pulse rounded-xl bg-strong" />
          <base-card class="animate-pulse rounded-xl bg-strong" />
        </template>

        <div
          v-else-if="loyaltyCardsLoadingError"
          class="col-span-2 text-center font-bold text-fg-error"
        >
          {{ loyaltyCardsLoadingError }}
        </div>

        <template v-else>
          <loyalty-card
            v-for="(card, idx) in loyaltyCards"
            :key="idx"
            :card="card"
            class="cursor-pointer transition duration-200"
            tabindex="0"
            role="button"
            @keyup.enter="onClick($event, card)"
            @click="onClick(card)"
          />
        </template>
      </div>
    </div>
  </base-aside-page>
</template>
